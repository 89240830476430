import React from "react";

import { Row, Col, Form, Space } from "antd";
import Nav from "../../Components/Nav";
import { Paragraph } from "./styled.components";
import {
	Container,
	StyleButton,
	Heading2,
	Heading,
	StyledLabel,
} from "../../Components/styled.components";
import CustomInput from "../../Components/CustomInput";
import CustomTable from "../../Components/CustomTable";
import { ReactComponent as DeleteIcon } from "../../Assets/svg/delete.svg";
import { ReactComponent as EditIcon } from "../../Assets/svg/edit.svg";

import NavigationSelect from "../../Components/NavigationSelect";
import CustomModal from "../../Components/CustomModal";
import CustomSelect from "../../Components/CustomSelect";
import httpClient from "../../axios";
import { openNotification } from "../../helper";
import { useLocation } from "react-router-dom";

const AdminUser = () => {
	const [form] = Form.useForm();
	const [editForm] = Form.useForm();
	const http = httpClient();
	const location = useLocation();

	const [deleteUserId, setDeleteUserId] = React.useState("");
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [userList, setUserList] = React.useState([]);
	const [roles, setRoles] = React.useState([]);
	const [selectedRole, setSelectedRole] = React.useState({});
	const [editLoading, setEditLoading] = React.useState(false);
	const [isEditModalVisible, setEditModalVisible] = React.useState(false);
	const [editUserId, setEditUserId] = React.useState("");
	const [selectedEditRole, setSelectedEditRole] = React.useState({});
	const [pagination, setPagination] = React.useState({
		itemsPerPage: 10,
		page: 1,
		totalItems: "",
	});
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const [userLoading, setUserLoading] = React.useState(false);
	const [rolesLoading, setRolesLoading] = React.useState(false);
	const [createLoading, setCreateLoading] = React.useState(false);

	const onFinish = (values) => {
		setCreateLoading(true);
		http
			.post("/auth/register", {
				email: values.email,
				password: values.password,
				password_confirmation: values.password_confirmation,
				first_name: values.first_name,
				last_name: values.last_name,
				emergency_contact: "2626262",
				role: selectedRole,
			})
			.then((response) => {
				if (response.status === 201) {
					setCreateLoading(false);
					resetForm();
					fetchUsers();
				} else {
					throw response;
				}
			})
			.catch((error) => {
				openNotification({
					type: "error",
					message: error.response.data.message,
					errors: error.response.data.errors,
				});
				console.error(
					"error",
					error.response.data.errors,
					error.response.data.message
				);
				setCreateLoading(false);
			});
	};
	const onFinishFailed = (values) => {
		console.error("error:", values);
	};
	const handleOpenModal = (data) => {
		setDeleteUserId(data.key);
		setIsModalVisible(true);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const fetchUsers = (itemsPerPage, page) => {
		setUserLoading(true);
		http
			.get(`/users?role=${location.pathname.split("/")[1]}`, {
				params: {
					limit: itemsPerPage || 10,
					page: page || 1,
				},
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return {
								key: item.id,
								first_name: item.first_name,
								last_name: item.last_name,
								email: item.email,
								role: item.role || "",
							};
						});
						setPagination({
							itemsPerPage: response.data.meta.per_page,
							totalItems: response.data.meta.total,
							page: response.data.meta.current_page,
						});
						setUserList({ data, meta: response.data.meta });
						setUserLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setUserLoading(false);
				openNotification({
					type: "error",
					message: error.response.data.message,
					errors: error.response.data.errors,
					status: error.response.status,
				});
				console.error("error", error);
			});
	};
	const handleDelete = (userId) => {
		setDeleteLoading(true);
		http
			.delete(`/users/${userId}`)
			.then((response) => {
				if (response.status === 204) {
					handleCancel();
					setDeleteLoading(false);
					fetchUsers();
				} else {
					throw response;
				}
			})
			.catch((error) => {
				console.error("error", error);
				setDeleteLoading(false);
			});
	};
	const fetchRoles = () => {
		setRolesLoading(true);
		http
			.get("/roles")
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return {
								key: item.id,
								label: item.value,
								value: item.name,
							};
						});
						setRoles(data);
						setRolesLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setRolesLoading(false);
				console.error("error", error);
			});
	};
	const handleOpenEditModal = (data) => {
		setEditUserId(data.key);
		setSelectedEditRole(data.role);
		editForm.setFieldsValue({
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			password: "",
			password_confirmation: "",
		});
		setEditModalVisible(true);
	};
	const handleCancelEdit = () => {
		setEditModalVisible(false);
	};
	const onFinishEdit = (values) => {
		setEditLoading(true);
		http
			.put(`/users/${editUserId}`, {
				email: values.email,
				password: values.password,
				password_confirmation: values.password_confirmation,
				first_name: values.first_name,
				last_name: values.last_name,
				emergency_contact: "2626262",
				role: selectedEditRole,
				fn_group: selectedEditRole,
			})
			.then((response) => {
				if (response.status === 200) {
					fetchUsers();
					setEditLoading(false);
				} else {
					throw response;
				}
			})
			.catch((error) => {
				openNotification({
					type: "error",
					message: error.response.data.message,
					errors: error.response.data.errors,
					status: error.response,
				});
				setEditLoading(false);
				console.error(
					"error",
					error.response.data.errors,
					error.response.data.message
				);
			});
	};

	const onChangePagination = (page, pageSize) => {
		setPagination({
			itemsPerPage: pageSize,
			page: page,
		});
		fetchUsers(pageSize, page);
	};

	const resetForm = () => {
		setSelectedRole({});
		form.resetFields();
	};

	React.useEffect(() => {
		resetForm();
		fetchUsers();
		fetchRoles();
	}, [location]);

	const columns = [
		{
			title: "FN Group Name",
			dataIndex: "role",
			key: "role",
		},
		{
			title: "First Name",
			dataIndex: "first_name",
			key: "first_name",
		},
		{
			title: "Last Name",
			dataIndex: "last_name",
			key: "last_name",
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<Space size="middle">
					<DeleteIcon onClick={() => handleOpenModal(text)} />
					<EditIcon onClick={() => handleOpenEditModal(text)} />
				</Space>
			),
		},
	];

	return (
		<>
			<Nav />
			<Container>
				<Row>
					<Col span={24}>
						<Heading2>Users</Heading2>
					</Col>
				</Row>
				<Row gutter={[16, 16]} style={{ justifyContent: "space-between" }}>
					<Col xs={24} md={12} lg={6}>
						<NavigationSelect />
					</Col>
					<Col xs={24} md={12} lg={6}>
						{/* <StyleButton >Upload User</StyleButton> */}
					</Col>
				</Row>
				<Row style={{ marginTop: "50px" }}>
					<Col xs={24}>
						<Heading>
							{location.pathname === "/super-admin"
								? `Add New Super Admin User`
								: location.pathname === "/admin"
									? `Add New Admin User`
									: `Add New Final User`}
						</Heading>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={24}>
						<Paragraph>
							{location.pathname === "/super-admin"
								? `
							This user can create, edit and delete the following user types:
							super admin, admin, guests, final users. They also have access to
							see the stats and download CSV report.`
								: location.pathname === "/admin"
									? `
							This user can access user stats, add new group members, and CSV
							file report.`
									: `
							This user can login to the course with credentials, and their
							stats will be tracked.`}
						</Paragraph>
					</Col>
				</Row>
				<Row>
					<Col xs={24}>
						<Paragraph>
							*Password must contain 2 capital letters, 1 number and can only
							use 0-9, a-z , A-Z , !@#$%^&*?
						</Paragraph>
					</Col>
				</Row>
				<Form
					name="admin-form"
					form={form}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					style={{ marginTop: "30px" }}
				>
					<Row gutter={[16, 16]}>
						<Col xs={24} md={12} lg={8} xl={3}>
							<StyledLabel>FN Group Name</StyledLabel>
							<CustomSelect
								options={roles}
								loading={rolesLoading}
								name="role"
								rules={[
									{
										required: true,
										message: "Please input name!",
									},
								]}
								value={selectedRole}
								onSelectValue={setSelectedRole}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={3}>
							<CustomInput
								formlabel="First Name"
								name="first_name"
								rules={[
									{
										required: true,
										message: "Please input first name!",
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={3}>
							<CustomInput
								formlabel="Last Name"
								name="last_name"
								rules={[
									{
										required: true,
										message: "Please input last name!",
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={4}>
							<CustomInput
								formlabel="Email Address"
								name="email"
								rules={[
									{
										type: "email",
										message: "The input is not valid E-mail!",
									},
									{
										required: true,
										message: "Please input email address!",
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={3}>
							<CustomInput
								formlabel="Password"
								name="password"
								type="password"
								hasFeedback
								rules={[
									{
										required: true,
										message: "Please input password!",
									},
								]}
							/>
						</Col>
						<Col xs={24} md={12} lg={8} xl={3}>
							<CustomInput
								formlabel="Confirm Password"
								name="password_confirmation"
								type="password"
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "Please confirm your password!",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue("password") === value) {
												return Promise.resolve();
											}
											return Promise.reject(
												new Error("Passwords do not match!")
											);
										},
									}),
								]}
							/>
						</Col>
						<Col
							xs={24}
							md={12}
							lg={8}
							xl={3}
							style={{ display: "flex", alignItems: "center" }}
						>
							<StyleButton
								style={{ width: "100%" }}
								htmlType="submit"
								loading={createLoading}
							>
								Save User
							</StyleButton>
						</Col>
					</Row>
					<Row style={{ marginTop: "50px" }}>
						<Col xs={24}>
							<Heading>
								{location.pathname === "/super-admin"
									? `Super Admin User`
									: location.pathname === "/admin"
										? `Admin User`
										: `Final User`}</Heading>
						</Col>
					</Row>
				</Form>
				<CustomModal
					isModalVisible={isModalVisible}
					title="Confirm Delete Usser"
					loading={deleteLoading}
					handleOk={() => handleDelete(deleteUserId)}
					handleCancel={handleCancel}
				>
					<div>
						Are you sure you want to delete this user. This action is
						irreversible!
					</div>
				</CustomModal>
				<CustomModal
					isModalVisible={isEditModalVisible}
					title="Edit User"
					loading={editLoading}
					handleCancel={handleCancelEdit}
					destroyOnClose
					footer={null}
				>
					<Form
						name="admin-edit-form"
						form={editForm}
						onFinish={onFinishEdit}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						style={{ marginTop: "30px" }}
					>
						<Row gutter={[16, 16]}>
							<Col xs={24}>
								<StyledLabel>FN Group Name</StyledLabel>
								<CustomSelect
									options={roles}
									loading={rolesLoading}
									name="role"
									rules={[
										{
											required: true,
											message: "Please input name!",
										},
									]}
									value={selectedEditRole}
									onSelectValue={setSelectedEditRole}
								/>
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="First Name"
									name="first_name"
									rules={[
										{
											required: true,
											message: "Please input first name!",
										},
									]}
								/>
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="Last Name"
									name="last_name"
									rules={[
										{
											required: true,
											message: "Please input last name!",
										},
									]}
								/>
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="Email Address"
									name="email"
									rules={[
										{
											type: "email",
											message: "The input is not valid E-mail!",
										},
										{
											required: true,
											message: "Please input email address!",
										},
									]}
								/>
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="Password"
									name="password"
									type="password"
									hasFeedback
									rules={[
										{
											required: true,
											message: "Please input password!",
										},
									]}
								/>
							</Col>
							<Col xs={24}>
								<CustomInput
									formlabel="Confirm Password"
									name="password_confirmation"
									type="password"
									dependencies={["password"]}
									hasFeedback
									rules={[
										{
											required: true,
											message: "Please confirm your password!",
										},
										({ getFieldValue }) => ({
											validator(_, value) {
												if (!value || getFieldValue("password") === value) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error("Passwords do not match!")
												);
											},
										}),
									]}
								/>
							</Col>
							<Col xs={24} style={{ display: "flex", alignItems: "center" }}>
								<StyleButton
									loading={editLoading}
									style={{ width: "100%" }}
									htmlType="submit"
								>
									Save User
								</StyleButton>
							</Col>
						</Row>
					</Form>
				</CustomModal>
				<Row style={{ marginTop: "50px" }}>
					<Col span={24}>
						<CustomTable
							loading={userLoading}
							dataSource={userList}
							columns={columns}
							pagination={{
								pageSize: pagination.itemsPerPage,
								current_page: pagination.page,
								total: pagination.totalItems,
								showSizeChanger: true,
								onChange: onChangePagination,
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default AdminUser;
