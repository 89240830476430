import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomSelect from "./CustomSelect";

const NavigationSelect = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const onSelectValue = (value) => {
		navigate(value);
	};
  
  const [options] = React.useState([
		{ label: "Super Admin User", value: "/super-admin" },
		{ label: "Admin User", value: "/admin" },
		{ label: "Final User", value: "/final-user" },
	]);
  

	return (
		<CustomSelect
			options={options}
			name="Super"
			defaultValue={
				options.map((option) => option.value === location.pathname)[0]?.label
			}
			value={location.pathname}
			onSelectValue={onSelectValue}
		/>
	);
};

export default NavigationSelect;
