import React from "react";
import { StyledTable } from "./styled.components";

const CustomTable = (props) => {
	const { dataSource, columns, loading, pagination } = props;
	return (
		<StyledTable
			loading={loading}
			dataSource={dataSource}
			columns={columns}
			pagination={pagination}
		/>
	);
};

export default CustomTable;
