import { StyledSelect } from "../styled.components";

const Select = (props) => {
	const { defaultValue = '', value = "", options = [], onSelectValue, style, rules } = props;
	const { Option } = StyledSelect;

	return (
		<>
			<StyledSelect defaultValue={defaultValue} style={style} value={value} onSelect={onSelectValue} rules={rules}>
				{options.map((option, index) => {
					return (
						<Option key={`option-${index}`} value={option.value}>
							{option.label}
						</Option>
					);
				})}
			</StyledSelect>
		</>
	);
};

export default Select;
