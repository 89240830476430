import React from "react";
import { notification } from "antd";

export const openNotification = (data) => {
	if (data.status === 401) {
		localStorage.removeItem("token");
		window.location.href = "/";
	}
	notification[data.type]({
		message: data.message,
		description: (
			<ul>
				{data.errors
					? Object.values(data.errors).map((error) => {
							return <li>{error}</li>;
					  })
					: ""}
			</ul>
		),
	});
};
