import React from "react";

import { Row, Col, Form } from "antd";
import Nav from "../../Components/Nav";
import {
	Container,
	StyleButton,
	Heading2,
	TableLink,
	Label,
} from "../../Components/styled.components";
import CustomStatTable from "../../Components/CustomStatTable";
import CustomSelect from "../../Components/CustomSelect";
import httpClient from "../../axios";
import { openNotification } from "../../helper";
import moment from "moment";
import CustomModal from "../../Components/CustomModal";

const StatsOne = () => {
	const http = httpClient();
	const [pagination, setPagination] = React.useState({
		itemsPerPage: 10,
		page: 1,
		totalItems: "",
	});
	const [options] = React.useState([
		{ Label: "Select Module", value: "Module" },
	]);
	const [optionsFilter] = React.useState([
		{ Label: "Filter by", value: "filter" },
	]);
	const [statList, setStatList] = React.useState([]);
	// const [metaList, setMetaList] = React.useState({});
	const [details, setDetails] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [isModalVisible, setIsModalVisible] = React.useState(false);

	const fetchStats = (itemsPerPage, page) => {
		setLoading(true);
		http
			.get(`users/trainings`, {
				params: {
					limit: itemsPerPage || 10,
					page: page || 1,
				},
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return item;
							// return item.quizzes;
						});
						setPagination({
							itemsPerPage: response.data.meta.per_page,
							totalItems: response.data.meta.total,
							page: response.data.meta.current_page,
						});
						setStatList(data);
						// setMetaList(response.data.meta);
						setLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setLoading(false);
				openNotification({
					type: "error",
					message: error.response.data.message,
					errors: error.response.data.errors,
					status: error.response.status,
				});
				console.error("error", error);
			});
	};

	const onChangePagination = (page, pageSize) => {
		setPagination({
			itemsPerPage: pageSize,
			page: page,
		});
		fetchStats(pageSize, page);
	};

	const onSeeDetails = (data) => {
		if (data && data.sections) {
			setDetails(data.sections);
			setIsModalVisible(true);
		}
	};
	const handleCancelModal = () => {
		setIsModalVisible(false);
	};
	const onReset = (id) => {
		http
			.put(`trainings/${id}/reset`)
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						openNotification({
							type: 'success',
							message: "Training Reset successfully!",
							status: response.status,
						})
						fetchStats();
						setLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setLoading(false);
				openNotification({
					type: "error",
					message: error.response.data.message,
					errors: error.response.data.errors,
					status: error.response.status,
				});
				console.error("error", error);
			});
	};

	React.useEffect(() => {
		fetchStats();
	}, []);

	const columns = [
		{
			title: "Fire Extinguisher Training",
			children: [
				{
					title: "User Name",
					dataIndex: ["email"],
				},
			],
		},
		{
			title: "",
			children: [
				{
					title: "Scenario",
					dataIndex: 'trainings',
					key: "scenario",
					render: (trainings) => trainings.map(training => <p>{training.name}</p>),
				},
			]
		},
		{
			title: "",
			children: [
				{
					title: "Attempts",
					dataIndex: 'trainings',
					key: "attempts",
					render: (trainings) => trainings.map(training => <p>{training.attempts}</p>),
				}
			]
		},
		{
			title: "",
			children: [
				{
					title: "Completion Date",
					dataIndex: 'trainings',
					key: "completed_date",
					render: (trainings) => trainings.map(training => <p>{moment(training.completed_date).format("d/M/Y")}</p>),
				}
			]
		},
		{
			title: "",
			children: [
				{
					title: "Completed",
					dataIndex: "trainings",
					key: "completed_date",
					render: completed_date => {
						if (completed_date != "" || completed_date != null) {
							return <img src={require('../../Assets/svg/tick.svg').default} />;
						} else {
							return <img src={require('../../Assets/svg/close.svg').default} />;
						}
					}
				}
			]
		},

		// {
		// 	title: "Landfill",
		// 	children: [
		// 		{
		// 			title: "Start Date",
		// 			render: (text) => {
		// 				if (text.quizes[0] && text.quizes[0].start_date) {
		// 					return moment(new Date(text.quizes[0].start_date)).format(
		// 						"MM/DD/YYYY"
		// 					);
		// 				} else {
		// 					return "";
		// 				}
		// 			},
		// 			key: "landfill_start_date",
		// 		},
		// 		{
		// 			title: "Completeion Date",
		// 			render: (text) => {
		// 				if (text.quizes[0] && text.quizes[0].completion_date) {
		// 					return moment(new Date(text.quizes[0].completion_date)).format(
		// 						"MM/DD/YYYY"
		// 					);
		// 				} else {
		// 					return "0%";
		// 				}
		// 			},
		// 			key: "landfill_completion_date",
		// 		},
		// 		{
		// 			title: "Completion %",
		// 			render: (text) => {
		// 				if (text.quizes[0] && text.quizes[0].completion_percentage) {
		// 					return `${text.quizes[0].completion_percentage}%`;
		// 				} else {
		// 					return "0%";
		// 				}
		// 			},
		// 			key: "landfill_completion_percentage",
		// 		},
		// 		{
		// 			title: "Score %",
		// 			render: (text) => {
		// 				if (text.quizes[0] && text.quizes[0].score_percentage) {
		// 					return `${text.quizes[0].score_percentage}%`;
		// 				} else {
		// 					return "";
		// 				}
		// 			},
		// 			key: "landfill_score_percentage",
		// 		},
		// 		{
		// 			title: "Result",
		// 			render: (text) => {
		// 				if (text.quizes[0] && text.quizes[0].score_percentage) {
		// 					return text.quizes[0].score_percentage >= 60 ? (
		// 						<span style={{ color: "#00A706" }}>PASS</span>
		// 					) : (
		// 						<span style={{ color: "#BB0000" }}>FAIL</span>
		// 					);
		// 				} else if (
		// 					text.quizes[1] &&
		// 					text.quizes[1].score_percentage === 0
		// 				) {
		// 					return <span style={{ color: "#BB0000" }}>FAIL</span>;
		// 				} else {
		// 					return "N/A";
		// 				}
		// 			},
		// 			key: "landfill_result",
		// 		},
		// 		{
		// 			title: "Details",
		// 			render: (text) => (
		// 				<TableLink onClick={() => onSeeDetails(text.quizes[0])}>
		// 					See
		// 				</TableLink>
		// 			),
		// 		},
		// 	],
		// },
		// {
		// 	title: "Transfer Station",
		// 	children: [
		// 		{
		// 			title: "Start Date",
		// 			render: (text) => {
		// 				if (text.quizes[1] && text.quizes[1].start_date) {
		// 					return moment(new Date(text.quizes[1].start_date)).format(
		// 						"MM/DD/YYYY"
		// 					);
		// 				} else {
		// 					return "";
		// 				}
		// 			},
		// 			key: "transfer_start_date",
		// 		},
		// 		{
		// 			title: "Completeion Date",
		// 			render: (text) => {
		// 				if (text.quizes[1] && text.quizes[1].completion_date) {
		// 					return moment(new Date(text.quizes[1].completion_date)).format(
		// 						"MM/DD/YYYY"
		// 					);
		// 				} else {
		// 					return "";
		// 				}
		// 			},
		// 			key: "transfer_completion_date",
		// 		},
		// 		{
		// 			title: "Completion %",
		// 			render: (text) => {
		// 				if (text.quizes[1] && text.quizes[1].completion_percentage) {
		// 					return `${text.quizes[1].completion_percentage}%`;
		// 				} else {
		// 					return "0%";
		// 				}
		// 			},
		// 			key: "transfer_completion_percentage",
		// 		},
		// 		{
		// 			title: "Score %",
		// 			render: (text) => {
		// 				if (text.quizes[1] && text.quizes[1].score_percentage) {
		// 					return `${text.quizes[1].score_percentage}%`;
		// 				} else {
		// 					return "0%";
		// 				}
		// 			},
		// 			key: "transfer_score_percentage",
		// 		},
		// 		{
		// 			title: "Result",
		// 			render: (text) => {
		// 				if (text.quizes[1] && text.quizes[1].score_percentage) {
		// 					return text.quizes[1].score_percentage >= 60 ? (
		// 						<span style={{ color: "#00A706" }}>PASS</span>
		// 					) : (
		// 						<span style={{ color: "#BB0000" }}>FAIL</span>
		// 					);
		// 				} else if (
		// 					text.quizes[1] &&
		// 					text.quizes[1].score_percentage === 0
		// 				) {
		// 					return <span style={{ color: "#BB0000" }}>FAIL</span>;
		// 				} else {
		// 					return "N/A";
		// 				}
		// 			},
		// 			key: "transfer_result",
		// 		},
		// 		{
		// 			title: "Details",
		// 			render: (text) => (
		// 				<TableLink onClick={() => onSeeDetails(text.quizes[1])}>
		// 					See
		// 				</TableLink>
		// 			),
		// 		},
		// 	],
		// },
		{
			title: "Reset",
			children: [
				{
					title: "Action",
					dataIndex: "trainings",
					render: (trainings) => trainings.map(training => <p style={{ fontSize: 10 }}><TableLink onClick={() => onReset(training.id)}>Reset</TableLink></p>),
				},
			],
		},
	];
	return (
		<>
			<Nav />
			<Container>
				<Row>
					<Col span={24}>
						<Heading2>Stats</Heading2>
					</Col>
				</Row>
				{/*
				<Row gutter={[16, 8]}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xl={4}
						style={{ display: "flex", alignItems: "flex-start" }}
					>
						<CustomSelect
							options={options}
							name="module"
							defaultValue="Select Module"
							value="Select Module"
						/>
					</Col>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xl={4}
						style={{ display: "flex", alignItems: "flex-start" }}
					>
						<CustomSelect
							options={optionsFilter}
							name="filter"
							defaultValue="Filter By"
							value="Filter By"
						/>
					</Col>
					<Col
						xs={12}
						sm={12}
						md={4}
						lg={3}
						xl={2}
						style={{ display: "flex", justifyContent: "flex-start", width: "" }}
					>
						<StyleButton>Apply</StyleButton>
					</Col>
					<Col
						xs={12}
						sm={12}
						md={4}
						lg={3}
						xl={2}
						style={{ display: "flex", justifyContent: "flex-start" }}
					>
						<StyleButton>Reset</StyleButton>
					</Col>
				</Row>
				*/}
				<Row style={{ marginTop: "50px" }}>
					<Col span={24}>
						<CustomStatTable
							loading={loading}
							dataSource={statList}
							columns={columns}
							pagination={{
								pageSize: pagination.itemsPerPage,
								current_page: pagination.page,
								total: pagination.totalItems,
								showSizeChanger: true,
								onChange: onChangePagination,
							}}
						/>
					</Col>
				</Row>

				<CustomModal
					isModalVisible={isModalVisible}
					title="Training Details"
					handleCancel={handleCancelModal}
					destroyOnClose
					footer={null}
				>
					{details &&
						details.map((section, index) => (
							<Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
								<Col xs={24}>
									<h2>{section.name}</h2>
								</Col>
								{section.questions &&
									section.questions.map((question, questionIndex) => (
										<>
											<Col xs={24}>
												<Label>{questionIndex + 1}. Question</Label>
												<div>{question.title}</div>
											</Col>
											<Col xs={24}>
												<Label>Answer</Label>
												<div>{question.answer}</div>
											</Col>
											<Col xs={24}>
												<Label>Corrent Answer</Label>
												<div>{question.correct_answer}</div>
											</Col>
										</>
									))}
							</Row>
						))}
				</CustomModal>
			</Container>
		</>
	);
};

export default StatsOne;
