import React from "react";
import { Form } from "antd";
import { StyledInput, StyledLabel } from "../styled.components";

const CustomInput = (props) => {
	const {
		formlabel = "",
		placeholder = "",
		setInput,
		type = "",
		value=""
	} = props;
	return (
		<>
			<StyledLabel>{formlabel}</StyledLabel>
			<Form.Item
				{...props}
			>
				<StyledInput value={value} onInput={setInput} placeholder={placeholder} type={type} />
			</Form.Item>
		</>
	);
};

export default CustomInput;
